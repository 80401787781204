import {useState, useEffect} from 'react'
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete'
import { useSession } from "../../context/SessionContext"

const AddressAutocomplete = (props) => {
    const [address, setAddress] = useState('')
    const {updater} = useSession()
    console.log(props)
    
    
    const handleSelect = async value => {
        const results = await geocodeByAddress(value)
        console.log('results:', results)
        const payload = {
                    AddressNum: results?.[0]?.address_components?.[0]?.short_name,
                    Address1: results?.[0]?.address_components?.[1]?.long_name,
                    City: results?.[0]?.address_components?.[2]?.long_name,
                    Country: results?.[0]?.address_components?.[3]?.short_name,
                    State: results?.[0]?.address_components?.[4]?.short_name,
                    Zip: results?.[0]?.address_components?.[5]?.long_name,
                    ZipPlus: results?.[0]?.address_components?.[6]?.long_name
                }
            await handleSubmit(payload)
        
    }

    const handleSubmit = async(e) => {
        console.log(e)
        try{
            await updater({
                id: props?.id,
                collection: props?.collection,
                payload: e
            })
            console.log('PROCEED... Successfully updated.')
        } catch {
            console.log('HALT... Error processing')
        }
    }
    return(
        <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                <label>Full Address
                    <input {...getInputProps({placeholder: 'Enter an address'})}/>
                    
                    
                        {suggestions?.map((suggestion) => 
                            <div {...getSuggestionItemProps(suggestion)}>{suggestion.description} {console.log(suggestion)}</div>
                        )}      
                    
                    
                </label>
            )}
        </PlacesAutocomplete>
    )
}
export default AddressAutocomplete