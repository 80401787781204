import {useEffect, useState, useRef} from 'react'
import {useSession} from '../../context/SessionContext'
import {useSearch} from '../../hooks/useSearch'

import { PiButton } from '../../components/base'
import { PiAccordian, PiSummary } from '../../components/accordian'
import {PiInput, PiLabel, PiDropdown, PiDropdownOptions, PiSelect} from '../../components/form'

const FileUploadField = (props) => {
    const {
        sessionData, 
        handleAddDrawer, 
        updater, 
        submitter, 
        uploadAttachment, 
        deleteAttachment} = useSession()

    const [data, setData] = useState('')

    const handleAdd = async(e) => {
        try {
            await updater({
                collection: e?.collection,
                id: e?.id,
                payload: e?.payload
            })
            console.log('Updated...')
            
        } catch {
            console.log('Error updating...')
        }
    }

    const handleChange = (e) => {
        
        const {name, value} = e.target
        setData({...data, [name]: value})
        
    }

    const handleFileChange = async(e) => {
        const fileRef = e.target.files[0]
        const urlRef = await uploadAttachment({id: props?.id, file: fileRef})
        setData({...data, ['File']: urlRef})
    }

    

    return(
        <>
        <input type='text' placeholder='Name' name='Name' value={data.Name} onChange={handleChange} />
        <input type='text' placeholder='Type' name='Type' value={data.Type} onChange={handleChange} />
        <input type='file' name='name' onChange={handleFileChange} />
            <button onClick={()=>handleAdd({
                            collection: props?.collection,
                            id: props?.id,
                            payload: {
                                [props?.field?.key]: props?.array ? [...props?.array, data] : [data]
                            }
        })}>Add</button>
        {props?.array?.map(arr => 
        <dl>
             
            
            
               
                            <>
                            <dt>Name</dt>
                            <dd>{arr['Name'] || '--'}</dd>
                            <dt>Type</dt>
                            <dd>{arr['Type'] || '--'}</dd>
                            <dt>File</dt>
                            <dd>
                            <iframe 
                                src={arr['File']} 
                                width="100%" 
                                height="150px"
                            />
                            </dd>
                            </>
                        
                
            
            
        
        </dl>
        )}
        
        
            
        </>
        
        
    )
}
export default FileUploadField