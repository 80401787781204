import {useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import {useSession} from '../context/SessionContext'
import {useAuth} from '../context/AuthContext'
import {PiNav, PiButton} from '../components/base'
import {FaCog, FaSignOutAlt, FaUserCircle} from 'react-icons/fa'

const Navbar = (props) => {
    const userSession = useSession()
    const {logOutUser} = useAuth()
    const navigate = useNavigate()
    const {loginModal, toggleLogin, sessionData, handleSessionUpdate} = userSession
    const handleLogout = () => {
        logOutUser()
        handleSessionUpdate({
            type: 'SET_CURRENT_USER',
            payload: {
                isUserLoggedIn: false
            }
        })
        navigate('/')
    }
    return(
        <div className='topNav'>
            <PiNav class='container-fluid'>
            <ul>
                <li>
                    <a className='contrast' onClick={()=>navigate('/')}>
                        <span className='brand'>
                            <strong>Telemi</strong>
                        </span>
                        <span className='beta'>
                            <sup> beta</sup>
                        </span>
                    </a>
                    
                </li>
            </ul>
            {sessionData?.currentUser?.isLoggedIn ? 
            <ul>
                <li>
                    <a onClick={()=>navigate('/dashboard')} ><FaUserCircle /> Dashboard</a>
                    
                </li>
                <li>
                    <a onClick={()=>navigate('/admin')} ><FaCog /> Settings</a> 
                </li>
                <li>
                    <a onClick={()=>handleLogout()} ><FaSignOutAlt /> Logoff</a>
                    
                </li>
            </ul>
            :
            <ul>
                <li>
                    <PiButton 
                        label='Login'
                        class='outline'
                        click={()=>toggleLogin(!loginModal)}
                    />
                </li>
            </ul>
            }
        </PiNav>
        </div>
        
    )
}
export default Navbar