import {useParams, Link, useNavigate} from 'react-router-dom'
import { FaArrowLeft, FaTrashAlt } from 'react-icons/fa'
import Drawer from '../../hooks/useDrawer'
import {useSession} from '../../context/SessionContext'
import Document, {useDocument} from '../../hooks/useField'

const Record = (props) => {

    const {sessionData, handleRemoveDrawer, deleter} = useSession()

    const handleClose = (drawer, index) => {
        handleRemoveDrawer(index)    
    }

    const handleDelete = (drawer, index) => {
        try {
                deleter({
                collection: drawer?.collection, 
                id: drawer?.id
            })
            handleRemoveDrawer(index)
            
        } catch {
            console.log('Error deleting...')
        }
    }

    console.log('DRAWERS:', props?.drawers)

    return(
        <>
            {props?.drawers?.map((drawer, index) => 
                <Drawer open={drawer.open}>
                    <div className='drawer-header'>
                        <h3>
                            <FaArrowLeft onClick={()=>handleClose(drawer, index)} />
                        </h3>
                        <hgroup>
                        <h3>
                            {drawer?.collection}
                        </h3>
                        </hgroup>
                        
                    </div>
                
                    <div className='drawer-content'>
                
                        <Document 
                            fields={drawer?.fields}
                            collection={drawer?.collection}
                            data={drawer?.data}
                            id={drawer?.id}
                            isOpen={drawer?.open}
                            sections={drawer?.sections}
                            close={()=>handleClose(index)}
                        />

                    </div>

                    <div className='drawer-footer'>
                        <h3>
                            Telemi
                        </h3>
                        <h5>
                            <a onClick={()=>handleDelete(drawer, index)}><FaTrashAlt /> Delete</a>
                        </h5> 
                    </div>

                </Drawer>    
            )}
        </>
    )
}
export default Record