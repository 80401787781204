import {useState, useCallback} from 'react'

export const usePiModal = () => {
    const [modalState, setModalState] = useState(false)
    return{
        modalState,
        toggleModal: useCallback(()=>setModalState(!modalState))
    }
}

export const PiModal = (props) => {
    return(
        <dialog open={props?.isOpen} className={props?.class}>
            <article>
                {props?.children}
            </article>
        </dialog>
    )
}

export const PiModalHeader = (props) => {
    return(
        <header>
            <a onClick={props?.toggle} aria-label='close' className='close'></a>
            {props?.children}
        </header>
    )
}

