import {createContext, useContext, useState, useRef, createRef, useReducer, useEffect} from 'react'
import sessionReducer from './sessionReducer'
import { db, fire, store } from '../services/firebase'
import { useFireCollection } from '../hooks/useFire'
import { deleteDoc } from 'firebase/firestore'

const SessionContext = createContext()

export const useSession = () => {
    return useContext(SessionContext)
}

export const SessionProvider = (props) => {
    const {
        collection, 
        query, 
        onSnapshot,
        querySnapshot,
        where, 
        getDocs, 
        getDoc, 
        addDoc, 
        updateDoc, 
        setDoc,
        doc,
        ref,
        getStorage,
        deleteObject,
        uploadBytes,
        getDownloadURL,
      } = fire

    //User State
    const initialState = {
        companies: '',
        currentCompany: '',
        currentUser: '',
        drawers: []
    }

    //Login
    const [loginModal, setLoginModal] = useState(false)
    const [scrollable, setScrollable] = useState(false)
    const toggleLogin = (e) => {setLoginModal(e)}

    //Fetch functions
    const fetchUser = async(email) => {
        const q = query(collection(db, 'Users'), where('Email', '==', email))
        const userRef = await getDocs(q)
        const user = userRef?.docs?.map(doc=>({
            id: doc.id, 
            FirstName: doc.FirstName, 
            Type: doc.Type, 
            ...doc.data()
        }))
        console.log(
            'email', email,
            'User Details:', user)
        handleSessionUpdate({
            type: 'SET_CURRENT_USER', 
            payload: {
                    firstName: user?.[0]?.FirstName,
                    email: user?.[0]?.Email,
                    isLoggedIn: true,
                    hasUserType: user?.[0]?.Type
                }})
    }

    const fetchCore = async() => {
        const q = query(collection(db, "Core"))
        const core = onSnapshot(q, (querySnapshot) => {
        let arr = querySnapshot.docs.map(doc => ({
            
            ...doc.data()
        }))
            console.log('Fetched Core')
            handleSessionUpdate({type: 'SET_CORE', payload: arr})
        })
    }

    // const fetchCompanies = async(email) => {
    //     const q = query(collection(db, "Companies"), where("Users", "array-contains", email))
    //     const companiesRef = await getDocs(q)
     
    //     const companies = await companiesRef.docs.map(doc => ({id: doc.id, ...doc.data()}))
    //     handleSessionUpdate({type: 'SET_COMPANIES', payload: companies})
    // }
    

    const generateLog = (e) => {
        return console.log(e)
    }

    const fetchLocations = async(id) => {
        const q = query(collection(db, "Locations"), 
        where("CompanyID", "==", id))
        const locations = onSnapshot(q, (querySnapshot) => {
        let arr = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))
            console.log('Fetched locations')
            handleSessionUpdate({type: 'SET_LOCATIONS', payload: arr})
        })
    }
    
    const fetchServices = async(id) => {
        const q = query(collection(db, "Services"), 
        where("CompanyID", "==", id))
        const services = onSnapshot(q, (querySnapshot) => {
        let arr = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))
            console.log('Fetched services')
            handleSessionUpdate({type: 'SET_SERVICES', payload: arr})
        })
    }

    const fetchTickets = async(id) => {
        const q = query(collection(db, "Tickets"), 
        where("CompanyID", "==", id))
        const tickets = onSnapshot(q, (querySnapshot) => {
        let arr = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))
            console.log('Fetched tickets')
            handleSessionUpdate({type: 'SET_TICKETS', payload: arr})
        })
    }

    const fetchAllLocations = () => {
        const q = query(collection(db, "Locations"))
        const locations = onSnapshot(q, (querySnapshot) => {
        let arr = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))
            console.log('Fetched locations')
            handleSessionUpdate({type: 'SET_LOCATIONS', payload: arr})
        })
    }

    //Get current date in ISO format
    const fetchDate = () => {
        const currentDate = new Date()
        return currentDate.toISOString().substring(0, 10)
      }

    //Update document record
    const updater = (e) => {
        const docRef = doc(db, e?.collection, e?.id)
        return updateDoc(docRef, {
            ...e?.payload,
            ['Updated']: fetchDate(),
            ['UpdatedBy']: sessionData?.currentUser?.email
          })
    }

    const fetcher = async(e) => {
        
        const d = doc(db, e?.core, e?.id)
        const docSnap = await getDoc(d)
        return docSnap
        // return getDoc(doc(db, e?.collection, e?.id))
        
    }

    //Submit new document record
    const submitter = (e) => {
        return addDoc(collection(db, e?.collection), {
            ...e?.payload,
            ['Created']: fetchDate(),
            ['CreatedBy']: sessionData?.currentUser,
            ['Permissions']: [
                {
                    ['Type']: 'Edit',
                    ['User']: sessionData?.currentUser?.email
                }
            ]
        })
    }

    const deleter = (e) => {
        const docRef = doc(db, e?.collection, e?.id)
        return deleteDoc(docRef)
    }

    const uploadAttachment = async(e) => {
        const storage = getStorage()
        const storageRef = ref(storage, `doc/${e?.id}/${e?.file?.name}`)
        await uploadBytes(storageRef, e?.file)
        return  getDownloadURL(storageRef)
    }

    const deleteAttachment = async(e) => {
        const storage = getStorage()
        const deleteFileRef = ref(storage, `doc/${e?.id}/${e?.file?.name}`)
        await deleteObject(deleteFileRef)
        return  console.log('Attachment removed...')
    }
    
    const [sessionData, dispatch] = useReducer(sessionReducer, initialState)

    //Update user session
    const handleSessionUpdate = (data) => {
        console.log('handleUpdate:', data)
        dispatch({
            type: data?.type, 
            payload: data?.payload
        })
    }

    //Add a drawer slot and hydrate with document data
    const handleAddDrawer = async(data) => {
        // const d = doc(db, data?.core, data?.id)
        // const docSnap = await getDoc(d)
        // const dataRef = docSnap ? docSnap.data() : 'Loading'
        // onSnapshot(d, (doc) => {
        //         doc ? f(doc.data()) : console.log('Doc not found...')
        //     }) 

        const drawer = sessionData?.core?.filter(f => 
            f?.id == data?.core)?.map(d => ({
                id: data?.id,
                collection: d?.id, 
                fields: d?.fields,
                sections: d?.section,
                primary: d?.primary,
                headers: d?.headers,
                open: true,
            }))
            
        sessionData.drawers.length < 1 ?
        handleSessionUpdate({
            type: 'SET_DRAWER',
            payload: [...drawer]
        }) :
        handleSessionUpdate({
            type: 'UPDATE_DRAWER',
            payload: [...sessionData?.drawers, ...drawer]
        })
    }

    //Remove a drawer slot
    const handleRemoveDrawer = (index) => {
        const copyDrawers = [...sessionData.drawers]
        copyDrawers?.splice(index, 1)
        handleSessionUpdate({
            type: 'SET_DRAWER',
            payload: [...copyDrawers]
        })
    }
    //Init firestore collections
    const companies = useFireCollection({
        collection: 'Companies', 
        user: sessionData?.currentUser?.email,
        hasUserType: sessionData?.currentUser?.hasUserType,
    }) 
    const services = useFireCollection({
        collection: 'Services',
        user: sessionData?.currentUser?.email,
        hasUserType: sessionData?.currentUser?.hasUserType,
    })
    const locations = useFireCollection({
        collection: 'Locations',
        user: sessionData?.currentUser?.email,
        hasUserType: sessionData?.currentUser?.hasUserType,
    })
    const orders = useFireCollection({
        collection: 'Orders',
        user: sessionData?.currentUser?.email,
        hasUserType: sessionData?.currentUser?.hasUserType,
    })
    const tickets = useFireCollection({
        collection: 'Tickets',
        user: sessionData?.currentUser?.email,
        hasUserType: sessionData?.currentUser?.hasUserType,
    })
    const accounts = useFireCollection({
        collection: 'Accounts',
        user: sessionData?.currentUser?.email,
        hasUserType: sessionData?.currentUser?.hasUserType,
    })
    const vendors = useFireCollection({
        collection: 'Vendors',
        user: sessionData?.currentUser?.email,
        hasUserType: sessionData?.currentUser?.hasUserType,
    })
    const users = useFireCollection({
        collection: 'Users',
        user: sessionData?.currentUser?.email,
        hasUserType: sessionData?.currentUser?.hasUserType,
    })
    generateLog(sessionData && sessionData)
// console.log('FIRE:', 
//     'Companies:', companies,
//     'Services:', services,
//     'Orders:', orders,
//     'Tickets:', tickets
// )
    

    const value = {
        companies,
        services,
        locations,
        orders,
        tickets,
        accounts,
        vendors,
        users,
        scrollable,
        loginModal,
        toggleLogin,
        sessionData,
        handleSessionUpdate,
        handleAddDrawer,
        handleRemoveDrawer,
        fetcher,
        updater,
        submitter,
        deleter,
        uploadAttachment,
        deleteAttachment,
        fetchDate,
        fetchUser,
        fetchCore,
        generateLog
    }

    return(
        <SessionContext.Provider value={value}>
            {props?.children}
        </SessionContext.Provider>
    )
}
