import {useEffect, useState, useRef} from 'react'
import {useSession} from '../../context/SessionContext'
import {useSearch} from '../../hooks/useSearch'

import { PiButton } from '../../components/base'
import { PiAccordian, PiSummary } from '../../components/accordian'
import {PiInput, PiLabel, PiDropdown, PiDropdownOptions} from '../../components/form'

const RelatedSelect = (props) => {
    const {sessionData, handleAddDrawer, updater, submitter} = useSession()

    const {set} = props
    const [open, setOpen] = useState(props?.open)
    const [fieldLabel, setFieldLabel] = useState()
    
    const relatedTable = useSession()?.[props?.remote?.toLowerCase()]

    useEffect(() => {
        const labelRef = fetchLabel()
        setFieldLabel(labelRef)
    },[])

    useEffect(()=> {
        const labelRef = fetchLabel()
        setFieldLabel(labelRef)
    }, [handleAddDrawer, props?.value])

    const fetchLabel = () => {
        const labelRef = relatedTable?.filter(f=>
            f?.id === props?.value)?.map(val => 
                props?.options?.map(field => val?.[field?.key] || '--')) 
        return labelRef
    }
        
    

    const {term, handleChangeSearchTerm, reset, results} = useSearch({
        core: relatedTable
    })

    // const isOpen = term ? true : false

    const handleClick = async(e) => {
        set({...props?.document, ...e?.payload})
        try {
            await updater({
                collection: e?.collection,
                id: e?.id,
                payload: e?.payload
            })
            console.log('Updated...')
            setOpen(false)
        } catch {
            console.log('Error updating...')
        }
        
    }

    const handleNew = async(e) => {
        
        const {id} = await submitter({
            collection: props?.remote, 
            payload: ''
        })
            
        return handleNewDrawerAndUpdate(id)

    }

    const handleNewDrawerAndUpdate = async(e) => {
        const id = e
        try{
            await updater({
                collection: props?.collection,
                id: props?.id,
                payload: {[props?.field?.key]: id}
            })
            console.log(`Updated ${props?.collection} document...`)
        } catch {
            console.log(`Error updating ${props?.collection} document...`)
        }

        handleAddDrawer({
            core: props?.remote, 
            id: id
        })
    }

    const handleOpenRemoteRecord = (e) => {
        
            handleAddDrawer({
                id: e?.id,
                core: props?.remote,
            })
    
    }

    console.log('RELATED SELECT:', props)

    return(
        <>
            <PiLabel>{props?.label}</PiLabel>
            <details role="list">   
            <summary aria-haspopup="listbox">
            
                {props?.value ? 
                    <a onClick={
                        ()=>handleOpenRemoteRecord({id: props?.value})
                    }> 
                        {fieldLabel} 
                    </a> : 
                ''}

            </summary>
            <ul role="listbox">
                <li>
                    <PiInput 
                        class='small-input'
                        type='search'
                        name='search'
                        change={(e)=>handleChangeSearchTerm(e)} 
                        value={term}  
                    />
                </li> 
                <li>
                    <a href='#' className='outline' onClick={()=>handleNew({
                        collection: props?.remote,
                        payload: {
                            [props?.field?.key]: props?.document?.id,
                        }
                    })} role="button">Add New</a>
                </li>
                {results?.map(opt => 
                    <li>
                    <a href='#' onClick={()=>handleClick({
                            collection: props?.collection,
                            id: props?.id,
                            payload: {
                                [props?.field?.key]: opt?.id
                            }
                        })}>
                        <div className='grid'>
                        {props?.options?.map(field =>
                                <div>
                                    {opt?.[field?.key] || '--'}
                                </div> 
                            )}
                        </div>
                        </a>
                    </li>
                    ) 
                } 
            </ul>          
        </details>
        </>
        
        
    )
}
export default RelatedSelect