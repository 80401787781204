import {useEffect, useState, useRef} from 'react'
import {useSession} from '../../context/SessionContext'
import Datalist from '../../modules/Datalist'
import { PiModal, PiModalHeader, usePiModal } from '../../components/modal'
import { Field } from '../../hooks/useField'
import {FaRegTrashAlt} from 'react-icons/fa'

const MapArrayField = (props) => {
    const {sessionData, handleAddDrawer, updater, submitter} = useSession()
    const [data, setData] = useState('')
    const [item, setItem] = useState()
    const addItem = usePiModal()
    const deleteItem = usePiModal()
    const handleAdd = async(e) => {
        try {
            await updater({
                collection: e?.collection,
                id: e?.id,
                payload: e?.payload
            })
            console.log('Updated...')
            addItem.toggleModal()
        } catch {
            console.log('Error updating...')
        }
    }

    const handleChange = (e) => {
        
        const {name, value} = e.target
        setData({...data, [name]: value})
        
    }

    const handleDelete = async(index) => {
        const copyArr = [...props?.array]
        copyArr?.splice(index, 1)
        try {
            await updater({
                collection: props?.collection,
                        id: props?.id,
                payload: {[props?.field?.key]: [...copyArr]}
            })
            console.log('Updated...')
            deleteItem.toggleModal()
        } catch {
            console.log('Error updating...')
        }
    }
    const handleConfirm = (e) => {
        deleteItem.toggleModal()
        setItem(e)
    }
    console.log('MAP-ARRAY:', props)

    return(
        <>
        <label>{props?.label}</label>
        <button onClick={()=>addItem.toggleModal()}>Add New</button>
        <PiModal isOpen={addItem.modalState}>
            <PiModalHeader toggle={()=>addItem.toggleModal()}>
                New {props?.label}
            </PiModalHeader>
                {props?.options?.map(opt => 
                    <>
                        <Field 
                            field={opt}
                            collection={props?.collection}
                            change={handleChange}
                            document={data}
                            value={data[opt?.key]}
                            placeholder={opt?.label}
                        />
                    {/* <input type='text' placeholder={opt.label} name={opt.key} value={data[opt.key]} onChange={handleChange} /> */}
                    </>
                )}
                <button onClick={()=>handleAdd({
                            collection: props?.collection,
                            id: props?.id,
                            payload: {
                                [props?.field?.key]: props?.array ? [...props?.array, data] : [data]
                            }
                })}>Add</button>
            </PiModal>
            <PiModal isOpen={deleteItem.modalState}>
                <PiModalHeader toggle={()=>deleteItem.toggleModal()}>Confirm</PiModalHeader>
                    Delete this {props?.label}
                <button onClick={()=>handleDelete(item)}>Delete</button>
                <button onClick={()=>deleteItem.toggleModal()}>Cancel</button>
            </PiModal>

        <Datalist 
            fields={props?.options}
            results={props?.array}
            click={(e)=>handleConfirm(e)}
        />

        {/* {props?.array?.map((arr, index) => 
        <dl>
            {props?.options?.map(opt => 
            
            
               
                            <>
                            <dt>{opt?.label}</dt>
                            <dd>{arr[opt?.key] || '--'}</dd>
                            
                            </>
                        
                
            
            
        )}
        <button onClick={()=>handleDelete(index)}><FaRegTrashAlt /> Delete</button>
        </dl>
        
        )} */}
        
        
            
        </>
        
        
    )
}
export default MapArrayField