import {PiButton, PiContainer, PiSection} from '../../components/base'
const NoCompanyBtn = (props) => {
    return(
        <PiContainer>
            <PiButton 
                label='Add your first company'
                click={props?.click}
            />
        </PiContainer>
    )
}
export default NoCompanyBtn