import {useEffect, useState, useRef} from 'react'
import {useSession} from '../../context/SessionContext'
import {useSearch} from '../../hooks/useSearch'
import {FaRegTrashAlt} from 'react-icons/fa'
import { PiButton } from '../../components/base'
import { PiAccordian, PiSummary } from '../../components/accordian'
import {PiInput, PiLabel, PiDropdown, PiDropdownOptions, PiSelect} from '../../components/form'

const NotesArrayField = (props) => {
    const {sessionData, handleAddDrawer, updater, submitter} = useSession()
    const [data, setData] = useState('')

    const handleAdd = async(e) => {
        
        try {
            await updater({
                collection: e?.collection,
                id: props?.id,
                payload: e?.payload
            })
            console.log('Updated...')
            
        } catch {
            console.log('Error updating...')
        }
    }

    const handleChange = (e) => {
        
        const {name, value} = e.target
        setData({...data, [name]: value})
        
    }

    const handleDelete = async(index) => {
        const copyArr = [...props?.array]
        copyArr?.splice(index, 1)
        try {
            await updater({
                collection: props?.collection,
                        id: props?.id,
                payload: {[props?.field?.key]: [...copyArr]}
            })
            console.log('Updated...')
            
        } catch {
            console.log('Error updating...')
        }
    }

    return(
        <>
        <input type='date' placeholder='Date' name='NoteDate' value={data.NoteDate} onChange={handleChange} />
        <input type='textarea' placeholder='Note' name='Note' value={data.Note} onChange={handleChange} />
            <button onClick={()=>handleAdd({
                            collection: props?.collection,
                            id: props?.id,
                            payload: {
                                [props?.field?.key]: props?.array ? [...props?.array, data] : [data]
                            }
        })}>Add</button>
        {props?.array ? props?.array?.map((arr, index) => 
        <dl>
            {props?.options?.map(opt => 
            
            
               
                            <>
                            <dt>{opt?.label}</dt>
                            <dd>{arr[opt?.key] || '--'}</dd>
                            </>
                        
                
            
            
        )}
        <p /><button onClick={()=>handleDelete(index)}><FaRegTrashAlt /> Delete</button>
        </dl>
        ) : ''}
        
        
            
        </>
        
        
    )
}
export default NotesArrayField