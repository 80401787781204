import {useState, useCallback, useEffect} from 'react'

export const useScrollLock = () => {
    const [scrollLock, setScrollLock] = useState(false)
    useEffect(()=> {
        scrollLock ? document.body.classList.add('noscroll') : document.body.classList.remove('noscroll')
    },[scrollLock])
    return{
        scrollLock,
        setScrollLock: useCallback((e)=>setScrollLock(e))
    }
}

const Container = (props) => {
    return(
        <div class={`container ${props?.scrollLock ? 'noscroll' : ''}`}>
            {props?.children}
        </div>
    )
}
export default Container