import {useEffect} from 'react'
import {useSession} from '../context/SessionContext'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import {useLoading} from '../hooks/useLoading'
import Checker from '../hooks/useSessionCheck'
import Container, {useScrollLock} from '../hooks/useScrollLock'
import {PiGrid} from '../components/base'

import NoCompanyBtn from './components/NoCompanyBtn'
import DashMenu from './components/DashMenu'
import UserGlance from './components/UserGlance'

import Record from './components/Record'


const Dashboard = () => {
    const {sessionData, companies} = useSession()
    const {loading, toggleLoading} = useLoading(true)
    const {scrollLock, setScrollLock} = useScrollLock()
    useEffect(()=> {
        sessionData?.drawers?.length ? setScrollLock(true) : setScrollLock(false)
    },[sessionData?.drawers])
    console.log(sessionData?.drawers?.length ? 'true' : 'false')
    return(
        <Container scrollLock={scrollLock}>
        <Checker
            toCheck={sessionData.currentUser.isLoggedIn}
            msg='Not logged in...'
        >
            <Checker
                toCheck={companies}
                msg={
                    <NoCompanyBtn 
                        click={console.log('Add company clicked')}
                    />
                }
            >
                {/* <>
                    <div className='griddy'>
                        <div className='closed-on-mobile'>
                            <DashMenu 
                                companies={sessionData?.companies}
                            />
                        </div>
                    <div className='content'> */}
                        <Routes>
                            <Route exact path="/"  element={<UserGlance />} />
                            
                        </Routes>
                    {/* </div>
                    </div> */}
                        <Record 
                                drawers={sessionData?.drawers}
                        />
                        
                {/* </> */}
            </Checker>
        </Checker> 
        </Container>
    )
}
export default Dashboard