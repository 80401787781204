import {useState, useEffect, useRef, useCallback} from 'react'
import { db, fire, store } from '../services/firebase'
import { useSession } from '../context/SessionContext'
export function useFireDoc(props) {
    const {
        collection, 
        query, 
        onSnapshot,
        querySnapshot,
        where, 
        getDocs, 
        getDoc, 
        addDoc, 
        updateDoc, 
        setDoc,
        doc,
        ref,
        getStorage,
        deleteObject,
        uploadBytes,
        getDownloadURL,
      } = fire
    const {sessionData} = useSession()
    const [data, setData] = useState('')
    const d = doc(db, props.collection, props.id)
    
    useEffect(() => {
        fetchData()
    },[])

    useEffect(()=>{
        startListener()
    },[])

    //Fetch initial doc data and commit it to state
    const fetchData = async() => {
        const docSnap = await getDoc(d)
        setData({id: docSnap.id, ...docSnap.data()})
    }

    //Listen for changes to doc data
    const startListener = () => {
        const unsubscribe = onSnapshot(d, (doc) => {
            const dataRef = doc.data()
            setData({id: doc.id, ...dataRef}) 
           }) 
           return unsubscribe
    }

    //Returns current date in DD/MM/YY format
    const fetchCurrentDate = () => {
        const currentDate = new Date()
        return currentDate.toISOString().substring(0, 10)
      }

    const handleChangeDoc = (e) => {
        e.preventDefault()
        const {name, value} = e?.target
        setData({...data, [name]: value})
    }

    //Update document on firestore
    const handleUpdateDoc = async(e) => {
        console.log('D:', d, '...data:', {...data})
        try{
            await updateDoc(d, {
                ...data,
                ['Updated']: fetchCurrentDate(),
                ['UpdatedBy']: sessionData?.currentUser?.email
              })
              console.log('Updated document successfully...')
        } catch {
            console.log('Error updating document...')
        }
    }
    

    return {
        data,
        changer: useCallback((e)=>handleChangeDoc(e)),
        updater: useCallback((e)=> handleUpdateDoc(e))

    }
}

export function useFireCollection(props) {
    const {
        collection, 
        query, 
        onSnapshot,
        querySnapshot,
        where, 
        getDocs, 
        getDoc, 
        addDoc, 
        updateDoc, 
        setDoc,
        doc,
        ref,
        getStorage,
        deleteObject,
        uploadBytes,
        getDownloadURL,
      } = fire
    
    const [data, setData] = useState()
    const q = props?.user ? props?.hasUserType === 'Admin' ? 
        query(collection(db, props?.collection)) : 
        query(collection(db, props?.collection), where("Users", "array-contains", props?.user)) || 
        query(collection(db, props?.collection), where("CreatedBy", "===", props?.user))
        : null
    
    useEffect(() => {
        fetchCollection()
    },[props?.user])

    //Fetch initial doc data and commit it to state
    const fetchCollection = async() => {
        if (q) {
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                let arr = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
                    console.log(`Fetched ${props?.collection} successfully...`)
                    setData(arr)
                })
            return unsubscribe
        } else console.log('Login first...')
        
    }

    return data
}