import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {SessionProvider} from './context/SessionContext'
import {AuthProvider} from './context/AuthContext'
import {PiContainer} from './components/base'
import Navbar from './modules/Navbar'
import Login from './modules/Login'
import Hello from './pages/Hello'
import Dashboard from './pages/Dashboard'
import Admin from './admin/Admin'
import Datalist from './modules/Datalist'
import './css/pico.css'
import './css/telemi.css'

const App = () => {
  
  return(
    <AuthProvider>
      <SessionProvider>
      <Router>
        <Navbar />
        
        <PiContainer class='container-fluid'> 
          
            <Routes>
              <Route exact path="/"  element={<Hello />} />
              <Route path="/dashboard/*"  element={<Dashboard />} />
              <Route path="/admin/*"  element={<Admin />} />
              <Route element={<Hello />} />
            </Routes>
            <Login />
          
        </PiContainer> 
        </Router>
        
        
      </SessionProvider>
    </AuthProvider>
  )
}

export default App;
