import {FaCopy} from 'react-icons/fa'
import {useClip} from '../hooks/useClip'

export const PiInput = (props) => {
    const clippy = async function useClip(props) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(props);
        } else {
          return document.execCommand('copy', true, props);
        }
      }
    return(
        <label htmlFor={props?.name}>
            {props?.label} {props?.copy ? <FaCopy onClick={()=>clippy(props?.value)} /> : ''}
            <input 
                type={props?.type}
                className={props?.class}
                id={props?.name}
                name={props?.name}
                placeholder={props?.placeholder}
                value={props?.value}
                onChange={props?.change}
                onBlur={props?.blur}
                onClick={props?.click}
                required={props?.required}
                disabled={props?.disabled}
            />
        </label>
    )
}

export const PiSelect = (props) => {

    const checkIfSelected = (opt) => {
        return props?.value === opt ? true : false
    }

    return(
        <label htmlFor={props?.name}>
            {props?.label}
            <select
                name={props?.name}
                id={props?.name}
                value={props?.value}
                onChange={props?.change}
                onBlur={props?.blur}
            >
                {props?.options ? props?.options?.map((opt, index) =>
                    <option 
                        value={opt} 
                        key={index}
                    >
                        {opt}
                    </option>
                ) :
                props?.children}
            </select>
        </label>
    )
}

export const PiLabel = (props) => {
    return(
        <label htmlFor={props?.name}>
            {props?.label}
            {props?.children}
        </label>
    )
}

export const PiDropdown = (props) => {
    return(
        <details role="list">
            <summary aria-haspopup="listbox">
                {props?.summary}
            </summary>
            <ul role="listbox">
                {props?.children}
            </ul>
            
        </details>
    )
}

export const PiDropdownOptions = (props) => {
    return(
        <ul role='listbox'>
            {props?.children}
        </ul>
    )
}


