import {useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'

const createDrawer = () => {
    const drawerRef = document.createElement('div')
    drawerRef.setAttribute('id', 'drawer-portal')
    return drawerRef
}

const Drawer = (props) => {
    const bodyRef = useRef(document.querySelector("body"))
    const drawerPortalRef = useRef(createDrawer())
    useEffect(() => {
        bodyRef.current.appendChild(drawerPortalRef.current)
        const portal = drawerPortalRef.current
        return () => {
            portal.remove()
            bodyRef.current.style.overflow = ''
        }
    }, [])
    return createPortal(
        <div className={`drawer-container ${props?.open ? 'is-open' : ''}`}>
            <div className={`drawer ${props?.open ? 'is-open' : ''}`}>
                <div className={`${props?.open === true ? 'right' : ''}`}>
                    {props?.children}
                </div>
            
            </div>
            <div className='backdrop' />
        </div>, drawerPortalRef.current
    )
}
export default Drawer