import {useEffect, useState, useRef} from 'react'
import {useSession} from '../../context/SessionContext'

const PermissionsArrayField = (props) => {
    const {sessionData, handleAddDrawer, updater, submitter} = useSession()
    const [data, setData] = useState('')

    const handleAdd = async(e) => {
        try {
            await updater({
                collection: e?.collection,
                id: e?.id,
                payload: e?.payload
            })
            console.log('Updated...')
            
        } catch {
            console.log('Error updating...')
        }
    }

    const handleChange = (e) => {
        
        const {name, value} = e.target
        setData({...data, [name]: value})
        
    }

    

    return(
        <>
        <input type='text' placeholder='Username' name='user' value={data.user} onChange={handleChange} />
        <input type='text' placeholder='Type' name='type' value={data.type} onChange={handleChange} />
            <button onClick={()=>handleAdd({
                            collection: props?.collection,
                            id: props?.id,
                            payload: {
                                [props?.field?.key]: props?.array ? [...props?.array, data] : [data]
                            }
        })}>Add</button>
        {props?.array?.map(arr => 
        <dl>
            {props?.options?.map(opt => 
            
            
               
                            <>
                            <dt>{opt?.label}</dt>
                            <dd>{arr[opt?.key] || '--'}</dd>
                            </>
                        
                
            
            
        )}
        </dl>
        )}
        
        
            
        </>
        
        
    )
}
export default PermissionsArrayField