export default (state, action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            return{
                ...state,
                currentUser: action.payload
            }
        case 'SET_CORE':
            return{
                ...state,
                core: action.payload
            }
        case 'SET_DRAWER': 
            return{
                ...state,
                drawers: action.payload
            }
        case 'UPDATE_DRAWER':
            return{
                ...state,
                drawers: action.payload
            }
        case 'SET_CURRENT_COMPANY':
            return{
                ...state,
                currentCompany: action.payload
            }
        case 'SET_COMPANIES':
            return{
                ...state,
                companies: action.payload
            }
        case 'SET_LOCATIONS':
            return{
                ...state,
                locations: action.payload
            }
        case 'SET_SERVICES':
            return{
                ...state,
                services: action.payload
            }
        case 'SET_TICKETS':
            return{
                ...state,
                tickets: action.payload
            }
        case 'SET_ORDERS':
            return{
                ...state,
                orders: action.payload
            }
        case 'SET_VENDORS':
            return{
                ...state,
                vendors: action.payload
            }
    }
}