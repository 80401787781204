import React, { useState, useCallback } from 'react'
import {PiContainer, PiCard} from '../components/base'

export function useLoading(initialValue) {
  
  const [loading, setLoading] = useState(initialValue || true)
  const toggleLoading = (value) => setLoading(value)
  
  return [loading, toggleLoading]
}

const Loading = () => {
    return(
        <PiContainer>
            <PiCard><a href='#' aria-busy='true'>Loading... Please wait.</a></PiCard>
        </PiContainer>
    )
}
export default Loading