import {useState, useEffect} from 'react'

export const useSessionCheck = ({toCheck}) => {
    const [checker, setChecker] = useState(false)
    const [isValid, setIsValid] = useState(false)
    console.log('checker', checker)
    useEffect(()=> {
        toCheck ? setIsValid(true) : setIsValid(false)
    },[toCheck])
    
    return {isValid}
}

const Checker = ({toCheck, msg, children}) => {
    console.log('fn Checker', toCheck)
    const {isValid} = useSessionCheck({toCheck})
    return(
        <>
            {isValid ? children : msg}
        </>
    )
}
export default Checker