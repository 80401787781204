import {PiCard, PiContainer, PiGrid} from '../components/base'
const Hello = () => {
    return(
        <PiContainer>
            <PiCard>
            <div>
                <hgroup>
                <h2>Inventory and Expense management</h2>
                <h3>for Communication and IT services.</h3>
                </hgroup>    
            </div>
            </PiCard>
        </PiContainer>
    )
}
export default Hello