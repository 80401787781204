import {useEffect} from 'react'
import {useSession} from '../../context/SessionContext'
import {useSearch} from '../../hooks/useSearch'
import { useFireCollection } from '../../hooks/useFire'
import {PiCard, PiHeader, PiFooter, PiFigure} from '../../components/base'
import { PiInput } from '../../components/form'
import DataList from '../../modules/Datalist'

const DashboardDataList = (props) => {
    const {sessionData, handleAddDrawer, submitter} = useSession()
    const data = useSession()?.[props?.core.toLowerCase()]
    const fireData = useFireCollection({collection: props?.core})
    const {term, handleChangeSearchTerm, set, reset, results} = useSearch({core: data})
    
    console.log('DATALIST:', data)
    const handleClick = (e) => {
        handleAddDrawer({
            id: e?.id,
            core: e?.core
        })
    }

    const handleNew = async(e) => {
        const {id} = await submitter({
            collection: e?.core,
            
        })
        return handleAddDrawer({
            core: e?.core, 
            id: id
        })
    }

    return(
        <PiCard class='card-table'>
            <PiHeader>
                <div class='grid'>
                    <div>
                    <div>{props?.label}</div>
                    <div>
                    
                    </div>
                    </div>
                    
                </div> 
            </PiHeader>
            <main>
            <div>
                    
                    <PiInput
                        class='small-input' 
                        type='search'
                        name='search'
                        change={(e)=>handleChangeSearchTerm(e)} 
                        value={term && term}  
                    />
                        
                    </div>
            <PiFigure>
                <DataList 
                    {...props}
                    results={results}
                    click={(e)=>handleClick(e)}
                />
            </PiFigure>
            </main>
                <footer>
                    <button role='link' onClick={(e)=>handleNew(props)}>
                        New
                    </button>
                </footer>
        </PiCard>
    )
}
export default DashboardDataList