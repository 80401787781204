import {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {useSession} from '../context/SessionContext'
import {useAuth} from '../context/AuthContext'
import {PiModal, PiModalHeader} from '../components/modal'
import {PiInput} from '../components/form'
import {PiButton} from '../components/base'

const Login = (props) => {
    
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const {
        loginModal, 
        toggleLogin, 
        sessionData, 
        handleSessionUpdate, 
        fetchCompanies,
        fetchUser
    } = useSession()
    const {login} = useAuth()
    const navigate = useNavigate()

    const handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target
        setData({...data, [name]: value})
    }

    const handleSubmit = async() => {
        
        setIsLoading(true)
        try {
            await login(data.user, data.pass)
            
            
            setTimeout(()=>handleLogin(data?.user), 1500)
        } catch {
            console.log('Error logging in')
        }
    }

    const handleLogin = (user) => {
        handleSessionUpdate({type: 'SET_CURRENT_USER', payload: user && user})
        fetchUser(user)
        // fetchCompanies(user) 
        navigate('/dashboard')
        toggleLogin(!loginModal)
    }

    return(
        <PiModal isOpen={loginModal}>
            <PiModalHeader toggle={()=>toggleLogin(!loginModal)}>
                Login
            </PiModalHeader>
            
            <PiInput 
                label='Email'
                type='email'
                placeholder='Enter your email'
                name='user'
                value={data?.user || ''}
                change={(e)=>handleChange(e)}
                required={true}
            />
            <PiInput 
                label='Password'
                type='password'
                placeholder='Enter your password'
                name='pass'
                value={data?.pass || ''}
                change={(e)=>handleChange(e)}
                required={true}
            />
            <PiButton 
                click={()=>handleSubmit()} 
                label='Submit'
            />
           
        </PiModal>
    )
}
export default Login