import {useState, useEffect, useRef, useCallback} from 'react'
import {useSession} from '../context/SessionContext'
import useClickAway from './useClickAway'
import { useFireDoc } from './useFire'

import {PiContainer, PiHeaderGroup, PiHeader, PiSection, PiGrid} from '../components/base'
import {PiInput, PiSelect} from '../components/form'
import RelatedDataList from '../pages/components/RelatedDataList'
import RelatedSelect from '../pages/components/RelatedSelect'
import PermissionsArrayField from '../pages/components/PermissionsArrayField'
import MapArrayField from '../pages/components/MapArrayField'
import FileUploadField from '../pages/components/FileUploadField'
import NotesArrayField from '../pages/components/NotesArrayField'
import AddressAutocomplete from '../pages/components/AddressAutocomplete'
import DatalistAccordian from '../pages/components/DatalistAccordian'
import { deleteUser } from 'firebase/auth'
import { db, fire, store } from '../services/firebase'

const useDocument = (props) => {
    const [document, setDocument] = useState('')
    const {updater, submitter, deleter} = useSession()
    
    const {
        onSnapshot, 
        getDoc, 
        doc,
      } = fire

    const handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e?.target
        setDocument({...document, [name]: value})
    }

    const handleSubmit = async(e) => {
        const {collection, id} = e
        try {
            await updater({collection: collection, id: id, payload: document});
            console.log('Submit completed. Record updated...')
        } catch {
            console.log('Error updating...')
        }
    }

    const handleDelete = async(e) => {
        const {collection, id, close} = e
        try {
            await deleter({collection: collection, id: id})
            console.log('Deleted...')
            close()
        } catch {
            console.log('Error deleting...')
        }
    }

    // const startListener = (e) => {
        
    //         const unsubscribe = onSnapshot(doc(db, e?.collection, e?.id), (doc) => {
    //             let arr = doc.data()
    //                 console.log(`Listening to document ${e?.id}`)
    //                 setDocument(arr)
    //             }) 
    //     return unsubscribe
    // }

    return{
        document,
        set: (e) => setDocument(e),
        change: useCallback((e) => handleChange(e)),
        remove: useCallback((e) => handleDelete(e)),
        blur: useCallback((e)=> handleSubmit(e))
    }
}

const Document = (props) => {
    const {sessionData} = useSession()
    const {data, updater, changer} = useFireDoc({collection: props?.collection, id: props?.id})
    const {set, change, remove, blur, setDoc, document} = useDocument()
    
    
    // const [data] = sessionData?.[props?.collection?.toLowerCase()]?.filter(f=> f?.id === props?.id)
    console.log('DOC', document,
    'docRef:', data)

    useEffect(() => {
        set(data)
        
    },[data])

    

    // console.log(
    //     'USEFIELD',
    //     'document:', document,
    //     'props:', props
    // )

    
    return(
        
        <PiGrid id='doc-section'>
            {props?.sections?.map(section => 
                <article>
                    <PiHeader>
                   {section}
                    </PiHeader>
            
                    {props?.fields?.filter(f=> 
                            f?.params?.tab === section)?.map(field =>
                                <>
                                    <Field 
                                        field={field}
                                        collection={props?.collection}
                                        change={changer}
                                        document={data}
                                        value={data?.[field?.key]}
                                        blur={()=>updater()}
                                        set={(e)=>set(e)}
                                    />
                                </>
                        )}
                        </article>
                )}
        </PiGrid>
    )
}

export const Field = (props) => {
    const {sessionData} = useSession()
    
    return(
        <>
            {[props?.field]?.map((el, index) => {
                switch (el?.type) {
                    
                    case 'text': 
                        return(
                            <PiInput 
                                name={el?.key}
                                type={el?.type}
                                label={el?.label}
                                copy={el?.params?.copyable}
                                value={props?.document?.[el?.key]}
                                change={(e)=>props?.change(e)}
                                blur={props?.blur}
                            />
                        )

                        case 'date': 
                        return(
                            <PiInput 
                                name={el?.key}
                                type='date'
                                label={el?.label}
                                copy={el?.params?.copyable}
                                value={props?.document?.[el?.key]}
                                change={(e)=>props?.change(e)}
                                blur={props?.blur}
                            />
                        )

                    case 'select': 
                        return(
                            <PiSelect
                                name={el?.key}
                                label={el?.label}
                                options={el?.ref?.fields}
                                value={props?.document?.[el?.key]}
                                change={(e)=>props?.change(e)}
                                blur={props?.blur}
                            >
                            </PiSelect>
                        )
                    case 'related-select':
                        return(
                            <RelatedSelect 
                                label={el?.label}
                                core={props?.collection}
                                remote={el?.collection}
                                options={el?.ref?.fields}
                                propValue={props?.document?.[el?.key]}
                                id={props?.document?.id}
                                {...props}
                            />
                        )
                    case 'related-datalist':
                        return(
                            <RelatedDataList 
                                label={el?.label}
                                core={props?.collection}
                                remote={el?.collection}
                                options={el?.ref?.fields}
                                value={props?.document?.[el?.key]}
                                id={props?.document?.id}
                                {...props}
                            />
                        )
                    case 'permissions-array':
                        return(
                            <PermissionsArrayField 
                                label={el?.label}
                                options={el?.ref?.fields}
                                array={props?.document?.[el?.key]}
                                id={props?.document?.id}
                                {...props}
                            />
                        )
                    case 'map-array':
                        return(
                            <MapArrayField 
                                label={el?.label}
                                options={el?.ref?.fields}
                                array={props?.document?.[el?.key]}
                                id={props?.document?.id}
                                {...props}
                            />
                        )
                    case 'file-array':
                        return(
                            <FileUploadField
                                label={el?.label}
                                options={el?.ref?.fields}
                                array={props?.document?.[el?.key]}
                                id={props?.document?.id}
                                {...props}
                            />
                        )
                    case 'notes':
                        return(
                            <NotesArrayField
                                label={el?.label}
                                options={el?.ref?.fields}
                                array={props?.document?.[el?.key]}
                                id={props?.document?.id}
                                {...props}
                            />
                        )
                        case 'address-autocomplete':
                        return(
                            <AddressAutocomplete
                                label={el?.label}
                                options={el?.ref?.fields}
                                array={props?.document?.[el?.key]}
                                id={props?.document?.id}
                                {...props}
                            />
                        )
                }
            })}
        </>
    )
}
export default Document