import {useSession} from '../context/SessionContext'
import { FaTrashAlt } from 'react-icons/fa'
import {PiTable, PiTableHeader, PiTableBody, PiTableRow, PiTableHead, PiTableData} from '../components/table' 

const Datalist = (props) => {
    const {sessionData} = useSession()
    
    return(
        <PiTable>
            <PiTableHeader>
                <PiTableRow>
                    {props?.fields?.map(el => 
                        <PiTableHead>{el?.label}</PiTableHead>
                    )}
                </PiTableRow>
            </PiTableHeader>
            <PiTableBody>
                {props?.results?.map((r, index)=> 
                    
                        <PiTableRow click={(e)=>props?.click({
                            id: r?.id,
                            core: props?.core
                        }) }>
                        
                                {props?.fields?.map(el => 
                                    <PiTableData>{r[el?.key] || '--'}</PiTableData>
                                )}
                                    {props?.delete && 
                                        <PiTableData>
                                            <FaTrashAlt onClick={()=>props?.delete(index)} />
                                        </PiTableData>
                                    }
                        </PiTableRow>
                    
                )}
            </PiTableBody>
        </PiTable>
    )
}
export default Datalist