import {useEffect} from 'react'
import {useSession} from '../../context/SessionContext'
import HashDataList from '../../modules/HashDataList'
import {PiSection, PiContainer, PiGrid, PiCard, PiNav} from '../../components/base'
import { useIcon } from '../../hooks/useIcon'
import DashboardDataList from './DashboardDataList'

import ServiceDetail from './ServiceDetail'
import FieldGroup from '../../modules/FieldGroup'
import LocationAccordian from './DatalistAccordian'
import Record from './Record'
const UserGlance = (props) => {
    const {
        sessionData, 
        companies,
        services,
        locations,
        fetchCore
    } = useSession()
    

    useEffect(() => {
        fetchCore()
        
    },[])

    
    
    return(
        <PiSection class='modules'>
            <hgroup>
                <h1>Hello {sessionData?.currentUser?.firstName}</h1>
                <h2>This is your profile at a glance.</h2>
            </hgroup>
            
                <PiGrid>
                    {[{
                        label: 'Companies',
                        data: 'companies',
                        core: 'Companies',
                        fields: [{
                            key: 'Name',
                            label: 'Company'
                    }]},
                    {
                        label: 'Locations',
                        data: 'locations',
                        core: 'Locations',
                        fields: [{
                            key: 'Name',
                            label: 'Location'
                            },
                            {
                            key: 'AddressNum',
                            label: 'Street Number'
                            }, {
                            key: 'Address1',
                            label: 'Address 1'
                            }, {
                            key: 'City',
                            label: 'City'
                            }, {
                            key: 'State',
                            label: 'State'
                            }, {
                            key: 'Zip',
                            label: 'Zip'
                            }]},
                    {
                        label: 'Services',
                        data: 'services',
                        core: 'Services',
                        fields: [
                            {
                                key: 'AssetID',
                                label: 'Asset ID'
                            },
                            {
                                key: 'Vendor',
                                label: 'Vendor'
                            }
                    ]},
                    {
                        label: 'Orders',
                        data: 'orders',
                        core: 'Orders',
                        fields: [
                            {
                                key: 'OrderNum',
                                label: 'Order'
                            },
                            {
                                key: 'Vendor',
                                label: 'Vendor'
                            }
                    ]},
                    {
                        label: 'Tickets',
                        data: 'tickets',
                        core: 'Tickets',
                        fields: [
                            {
                                key: 'TicketNum',
                                label: 'Ticket Number'
                            },
                            {
                                key: 'Vendor',
                                label: 'Vendor'
                            },
                            {
                                key: 'Date',
                                label: 'Date'
                            }
                        ]
                    }].map(card => 
                            
                            <DashboardDataList 
                                {...card}
                            />
                        
                        )}
                    </PiGrid>
                    {/* <LocationAccordian 
                        name='Services'
                        data={fireStarter?.services}
                        core='Services'
                    /> */}
        </PiSection>
    )
}
export default UserGlance