import { useSession } from "../context/SessionContext";
import { useFireCollection } from "../hooks/useFire";
import { useArray } from "../hooks/useArray";

const Admin = () => {
    const {updater} = useSession()
    const fireData = useFireCollection({collection: 'Core'}) 
    const fetchCore = (core) => {
        const arr = fireData?.filter(f=> f?.id === core)?.map(i => i?.fields)
        return arr?.[0]
    } 
    console.log(
        'Core:', fireData,
        'Core/Services:', 
        )

    const {shiftIndexUp} = useArray()
    const {shiftIndexDown} = useArray()

    const handleShiftDown = (arr, index, id) => {
        const newArr = shiftIndexDown(arr, index, 1)
        handleUpdate({id: id, payload: newArr})
    }
    const handleShiftUp = (arr, index, id) => {
        const newArr = shiftIndexUp(arr, index, 1)
        handleUpdate({id: id, payload: newArr})
    }

    const handleAddField = (arr, id) => {
        const newField = {key: 'New Field'}
        const newArr = [...arr, newField]
        handleUpdate({id: id, payload: newArr})
    }

    const handleUpdate = async(props) => {
        console.log('Payload:', props?.payload)
        try {
            await updater({
                collection: 'Core',
                id: props?.id,
                payload: {fields: props?.payload}
            })
            console.log('Updated...')
            
        } catch {
            console.log('Error updating...')
        }
    }
    
    // const servicesNew = servicesCore ? shiftIndexUp(servicesCore?.[0], 25, 1) : console.log('waiting...')

    
        // const testArr = [1,2,3,4,5,6,7,8]
        // const moveValue = (arr, v, m) => {
            
        //     const oldCopy = [...arr]
        //     console.log('Old', oldCopy)
        //     const currIndex = v
        //     const toIndex = v + m
        //     const element = oldCopy.splice(currIndex, 1)[0]
        //     oldCopy.splice(toIndex, 0, element)
        //     const newArr = [...oldCopy]
        //     console.log('New', newArr)
            
        // }

        // servicesCore ? moveValue(testArr, 3, 1) : console.log('waiting...')
return(
    <>
        {fireData?.map(el => 
            <article>
                <details>
                    <summary>
                        {el?.id}
                    </summary>
                    <button onClick={()=>handleAddField(fetchCore(el?.id), el?.id)}>Add Field</button>
                        <ul>
                            {fetchCore(el?.id)?.map((item, index) => 
                                <li>
                                    <details>
                                        <summary>
                                            <div className='grid'>
                                                <div>Group: {item?.params?.tab}</div>
                                                <div>Index: {index}</div>
                                                <div>Field: {item?.key}</div>
                                            </div>
                                        </summary>
                                        <button onClick={()=>handleShiftDown(fetchCore(el?.id), index, el?.id)}>+</button>
                                        <button onClick={()=>handleShiftUp(fetchCore(el?.id), index, el?.id)}>-</button>
                                    </details>
                                </li>
                            )}
                        </ul>
                </details>
            </article>
        )}
    </>
)
}
export default Admin