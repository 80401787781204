import {useState, useEffect, useCallback} from 'react'
import { useSession } from '../context/SessionContext'

export const useSearch = (props) => {
    // const data = useSession()?.[props?.core.toLowerCase()]
    const [term, setTerm] = useState('')
    const [searching, setSearching] = useState(false)
    const [data, setData] = useState(props?.core)
    const [results, setResults] = useState(data)

    useEffect(() => {
        handleSearch(term)
    }, [term])

    useEffect(() => {
        setData(props?.core)
    }, [props?.core])

    useEffect(()=>{
        setResults(data)
    },[data])

    const handleChangeSearchTerm = (e) => {
        e.preventDefault()
        const newValue = e.target.value
        setTerm(newValue)
    }

    const handleSearch = (term) => {
        const res = Array.isArray(data) ? data.filter((obj) => 
        Object.keys(obj).filter(f=> f != 'id').some((el)=>
        obj[el].toString().toLowerCase().indexOf(term.toLowerCase()) > -1)) : console.log('Not valid array')
        return setResults(res)
    }

    return {
        term, 
        handleChangeSearchTerm, 
        set: useCallback((e)=>{setData(e)}), 
        reset: useCallback(()=>{setTerm('')}), 
        results
    }
}