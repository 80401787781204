import {useRef} from 'react'
import {useSession} from '../../context/SessionContext'
import {useSearch} from '../../hooks/useSearch'
import { PiButton } from '../../components/base'
import { PiAccordian, PiSummary } from '../../components/accordian'
import {PiInput, PiLabel} from '../../components/form'
import { PiModal, PiModalHeader, usePiModal } from '../../components/modal'
import Datalist from '../../modules/Datalist'

const RelatedDataList = (props) => {
    const {sessionData, handleAddDrawer, updater, submitter} = useSession()
    const relatedTable = useSession()?.[props?.remote?.toLowerCase()]
    
    const data = useSession()?.[props?.remote?.toLowerCase()]

    const {term, handleChangeSearchTerm, reset, results} = useSearch({
        core: relatedTable
    })

    const relateItem = usePiModal()

    const isOpen = term ? true : false

    // const handleClick = async(e) => {
    //     try {
    //         await updater({
    //             collection: e?.collection,
    //             id: e?.id,
    //             payload: e?.payload
    //         })
    //         console.log('Updated...')
    //     } catch {
    //         console.log('Error updating...')
    //     }
    // }

    const handleClick = (e) => {
        handleAddDrawer({
            id: e?.id,
            core: props?.remote,
        })
    }

    const handleAttach = (e) => {
        updater({
            collection: props?.field?.collection,
            id: e?.id,
            payload: {
                [props?.field?.key]: props?.id
            }
        })
    }

    const handleNew = async(e) => {
        
        const {id} = await submitter({
            collection: e?.collection, 
            payload: e?.payload
        })
        return handleAddDrawer({
            core: e?.collection, 
            id: id
        })

    }

    const handleOpenRemoteRecord = (e) => {
        
        handleAddDrawer({
            id: e?.id,
            core: props?.field?.collection,
        })

}

    console.log(
        'RELATED DATALIST props:', props,
        'relatedTable:', props?.remote
        )

    return(
        <>
        <label>{props?.label}</label>
            <Datalist 
                fields={props?.options}
                results={data?.filter(f=>
                    f?.[props?.field?.key] && 
                    f?.[props?.field?.key] === props?.id)}
                click={(e)=>handleClick(e)}
            />
        <button onClick={()=>relateItem.toggleModal()}>Relate additional {props?.label}</button>
        <PiModal isOpen={relateItem.modalState}>
            <PiModalHeader toggle={()=>relateItem.toggleModal()}>Relate</PiModalHeader>
            <PiInput 
                type='search'
                name='search'
                change={(e)=>handleChangeSearchTerm(e)} 
                value={term}  
            />
                <Datalist 
                    fields={props?.options}
                    results={results}
                    click={(e)=>handleAttach(e)}
                />
            <footer>
            <a href="#" onClick={()=>handleNew({
                        collection: props?.remote,
                        payload: {
                            [props?.field?.key]: props?.id,
                        }
                    })} role="button">New</a>
            </footer>
        </PiModal>
        {/* <details>   
            <summary aria-haspopup="listbox">
            
                Relate {props?.label}

            </summary>
            <ul>
                
                    <PiInput 
                        type='search'
                        name='search'
                        change={(e)=>handleChangeSearchTerm(e)} 
                        value={term}  
                    />
                
                
                    <a href="#" onClick={()=>handleNew({
                        collection: props?.remote,
                        payload: {
                            [props?.field?.key]: props?.id,
                        }
                    })} role="button">New</a>
                
                <Datalist 
                    fields={props?.options}
                    results={results}
                    click={(e)=>handleAttach(e)}
                />
                {/* {results?.map(opt => 
                    <li>
                    <a className='secondary' onClick={()=>handleClick({
                            collection: props?.field?.collection,
                            id: opt?.id,
                            payload: {
                                [props?.field?.key]: props?.id
                            }
                        })}>
                            {props?.options?.map(field => opt?.[field?.key] || '--')}
                        </a>
                    </li>
                    ) 
                }  
            </ul>          
        </details> */}
                {/* <ul id='linked-list'>
                    <label>Linked {props?.label}</label>
                    <Datalist 
                        fields={props?.options}
                        results={data?.filter(f=>
                            f?.[props?.field?.key] && 
                            f?.[props?.field?.key] === props?.id)}
                        click={(e)=>handleClick(e)}
                    />
                    {/* {data?.filter(f=>
                        f?.[props?.field?.key] && f?.[props?.field?.key] === props?.id)?.map(opt => 
                        
                            <a 
                                onClick={()=>handleOpenRemoteRecord({
                                    id: opt?.id
                            })}><li>
                                {props?.options?.map(field => opt?.[field?.key])}
                                </li>
                            </a>
                        
                    )} 
                </ul> */}
        </>
    )
}
export default RelatedDataList