export const useArray = () => {
    return{
        copyArr: (arr) => {
            const newArr = [...arr]
            return newArr
        },
        addElement: (arr, el) => {
            const copyArr = [...arr]
            const newArr = [...arr, el]
            return newArr
        },
        deleteElement: (arr, el) => {
            const copyArr = [...arr]
            copyArr.slice(el, 1)
            return copyArr
        },
        shiftIndexUp: (arr, curr, inc) => {
            const copyArr = [...arr]
            const fromIndex = curr
            const toIndex = curr + 1
            const element = copyArr.splice(fromIndex, 1)[0]
            copyArr.splice(toIndex, 0, element)
            const newArr = [...copyArr]
            return newArr
        },
        shiftIndexDown: (arr, curr, inc) => {
            const copyArr = [...arr]
            const fromIndex = curr
            const toIndex = curr - 1
            const element = copyArr.splice(fromIndex, 1)[0]
            copyArr.splice(toIndex, 0, element)
            const newArr = [...copyArr]
            return newArr
        }
    }
}